/*
 * @Author: your name
 * @Date: 2021-09-18 09:48:27
 * @LastEditTime: 2021-09-29 08:56:55
 * @LastEditors: zshawk1982
 * @Description: In User Settings Edit
 * @FilePath: \media_web_client\src\router\index.js
 */
import Vue from "vue"
import Router from "vue-router"
import ElementUI from "element-ui"
import Home from '../views/Home.vue'
import 'element-ui/lib/theme-chalk/index.css';
import '../assets/css/public.css';

Vue.use(Router);
Vue.use(ElementUI);
const router =new Router({
    routes:[
        {
            path: '/',
            redirect: '/Homepage',
          },
          {
            path: '/',
            name: 'Home',
            component: Home,
            children: [
              {
                path: '/Homepage',
                name: 'Homepage',
                meta: {
                  title: '系统首页',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "dashboard" */ '../views/Homepage/Homepage.vue'
                  ),
              },
              {
                path: '/solutions',
                name: 'solutions',
                meta: {
                  title: '产品与解决方案',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "table" */ '../views/Deatail/solutions.vue'
                  ),
              },
              {
                path: '/project-case',
                name: 'project-case',
                meta: {
                  title: '项目案例',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "login" */ '../views/Deatail/project-case.vue'
                  ),
              },
              {
                path: '/news',
                name: 'news',
                meta: {
                  title: '新闻资讯',
                },
                component: () =>
                  import(
                    /* webpackChunkName: "login" */ '../views/Deatail/news.vue'
                  ),
              },
                  {
                    path: '/about-us',
                    name: 'about-us',
                    meta: {
                      title: '关于我们',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail/about-us.vue'
                      ),
                  },
                  {
                    path: '/join-us',
                    name: 'join-us',
                    meta: {
                      title: '加入我们',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail/join-us.vue'
                      ),
                  },
                  {
                    path: '/newsdetail6',
                    name: 'newsdetail6',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail6.vue'
                      ),
                  },

                  {
                    path: '/newsdetail1',
                    name: 'newsdetail1',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail1.vue'
                      ),
                  },
                  {
                    path: '/newsdetail2',
                    name: 'newsdetail2',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail2.vue'
                      ),
                  },
                  {
                    path: '/newsdetail3',
                    name: 'newsdetail3',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail3.vue'
                      ),
                  },
                  {
                    path: '/newsdetail4',
                    name: 'newsdetail4',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail4.vue'
                      ),
                  },
                  {
                    path: '/newsdetail5',
                    name: 'newsdetail5',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail5.vue'
                      ),
                  },
                  {
                    path: '/newsdetail7',
                    name: 'newsdetail7',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail7.vue'
                      ),
                  },
                  {
                    path: '/newsdetail8',
                    name: 'newsdetail8',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail8.vue'
                      ),
                  },
                  {
                    path: '/newsdetail9',
                    name: 'newsdetail9',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail9.vue'
                      ),
                  },
                  {
                    path: '/newsdetail10',
                    name: 'newsdetail10',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail10.vue'
                      ),
                  },
                  {
                    path: '/newsdetail11',
                    name: 'newsdetail11',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail11.vue'
                      ),
                  },
                  {
                    path: '/newsdetail12',
                    name: 'newsdetail12',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail12.vue'
                      ),
                  },
                  {
                    path: '/newsdetail13',
                    name: 'newsdetail13',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail13.vue'
                      ),
                  },
                  {
                    path: '/newsdetail14',
                    name: 'newsdetail14',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail14.vue'
                      ),
                  },
                  {
                    path: '/newsdetail15',
                    name: 'newsdetail15',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail15.vue'
                      ),
                  },
                  {
                    path: '/newsdetail16',
                    name: 'newsdetail16',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail16.vue'
                      ),
                  },
                  {
                    path: '/newsdetail17',
                    name: 'newsdetail17',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail17.vue'
                      ),
                  },
                  {
                    path: '/newsdetail18',
                    name: 'newsdetail18',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail18.vue'
                      ),
                  },
                  {
                    path: '/newsdetail19',
                    name: 'newsdetail19',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Deatail-2/newsdetail19.vue'
                      ),
                  },




                  {
                    path: '/solutionsdetail1',
                    name: 'solutionsdetail1',
                    meta: {
                      title: '政务平台',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/solutionsdetail1.vue'
                      ),
                  },
                  {
                    path: '/solutionsdetail2',
                    name: 'solutionsdetail2',
                    meta: {
                      title: '公检法司',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/solutionsdetail2.vue'
                      ),
                  },
                  {
                    path: '/solutionsdetail3',
                    name: 'solutionsdetail3',
                    meta: {
                      title: '新闻资讯',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/solutionsdetail3.vue'
                      ),
                  },
                  {
                    path: '/solutionsdetail4',
                    name: 'solutionsdetail4',
                    meta: {
                      title: '大数据类',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/solutionsdetail4.vue'
                      ),
                  },
                  {
                    path: '/product1',
                    name: 'product1',
                    meta: {
                      title: '公务员数字化管理系统',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/product1.vue'
                      ),
                  },
                  {
                    path: '/product2',
                    name: 'product2',
                    meta: {
                      title: '公务员数字化管理系统',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/product2.vue'
                      ),
                  },
                  {
                    path: '/product3',
                    name: 'product3',
                    meta: {
                      title: '民警绩效考评系统',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/product3.vue'
                      ),
                  },
                  {
                    path: '/product4',
                    name: 'product4',
                    meta: {
                      title: '项目实施流程管理系统',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/product4.vue'
                      ),
                  },
                  {
                    path: '/product5',
                    name: 'product5',
                    meta: {
                      title: '法院案件审判绩效管理软件',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/product5.vue'
                      ),
                  },


                  {
                    path: '/management1',
                    name: 'management1',
                    meta: {
                      title: '物联网大数据',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management1.vue'
                      ),
                  },
                  {
                    path: '/management2',
                    name: 'management2',
                    meta: {
                      title: '企业管理',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management2.vue'
                      ),
                  },
                  {
                    path: '/management3',
                    name: 'management3',
                    meta: {
                      title: '游戏娱乐',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management3.vue'
                      ),
                  },
                  {
                    path: '/management4',
                    name: 'management4',
                    meta: {
                      title: '行业娱乐',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management4.vue'
                      ),
                  },
                  {
                    path: '/management5',
                    name: 'management5',
                    meta: {
                      title: 'IT运维',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management5.vue'
                      ),
                  },
                  {
                    path: '/management6',
                    name: 'management6',
                    meta: {
                      title: 'SAAS平台',
                    },
                    component: () =>
                      import(
                        /* webpackChunkName: "login" */ '../views/Product/management6.vue'
                      ),
                  },

                  
            ],
          },
          
    ],
    scrollBehavior(to, from, savedPosition) {
      return { x: 0, y: 0 };
    },
})
 
//最后导出，因为要在main.js中引入

export default router
