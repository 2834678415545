import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import '../src/assets/icons/index'
Vue.use(Vant);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')