<template>
    <div>
  <div class="midlehide">
    <div class="footer">
      <div class="footerbox">
        <div class="footer-left">
          <div class="footercard">
            <svgicon :iconFileName="iconFileName"></svgicon>
            <img src="../assets/img/img-pc/weixin.png" />

            <p>扫一扫，了解更多</p>
          </div>
        </div>
        <div class="footer-right">
          <ul>
            网站首页
            <li>搜雅科技</li>
          </ul>
          <ul>
            产品与解决方案
            <router-link to="/solutionsdetail1" style="color: #fff"
              ><li>党政机关软件</li></router-link
            >
            <router-link to="/management1" style="color: #fff"
              ><li>企事业单位管理软件</li></router-link
            >
            <router-link to="/product1" style="color: #fff"
              ><li>示例产品</li></router-link
            >
          </ul>
          <ul>
            项目案例
          </ul>
          <ul>
            新闻资讯
          </ul>
          <ul>
            关于我们
            <router-link to="/about-us" style="color: #fff"
              ><li>公司简介</li></router-link
            >
            <router-link to="/about-us" style="color: #fff"
              ><li>企业文化</li></router-link
            >
            <router-link to="/about-us" style="color: #fff"
              ><li>荣誉资质</li></router-link
            >
            <router-link to="/about-us" style="color: #fff"
              ><li>联系我们</li></router-link
            >
          </ul>
        </div>
      </div>
    </div>
    <div class="footer1">
      <div>
        重庆搜雅科技有限公司版权所有
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502003356"
          ><img
            src="../assets/img/img-pc/beian.png"
            style="margin-bottom: -3px; margin-right: 6px"
          />渝公网安备50010502003356号</a
        >
        <a
          target="_blank"
          href="https://beian.miit.gov.cn/?recordcode=2022007003"
          >渝ICP备2022007003号</a
        >
      </div>
    </div>
  </div>
  <div class="mobilehide">
    <div class="footer">
    <div class="footer-right">
      <router-link to="/Homepage" style="color: #fff;"><p>网站首页</p></router-link>
      <router-link to="/solutions" style="color: #fff;"><p>产品与解决方案</p></router-link>
      <router-link to="/project-case" style="color: #fff;"><p>项目案例</p></router-link>
      <router-link to="/news" style="color: #fff;"><p>新闻资讯</p></router-link>
      <router-link to="/about-us" style="color: #fff;"><p>关于我们</p></router-link>

    </div>
    <div class="footer-left">
      
      <div class="left-weixin">
        <svgicon :iconFileName="iconFileName"></svgicon>
        <img src="../assets/img/img-pc/weixin.png" width="149">
      </div>
      <p>扫一扫，了解更多</p>
    </div>
    
  </div>
  <div class="footer1">

      <P>重庆搜雅科技有限公司版权所有</P>
      <p>
      <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50010502003356">
        <img src="../assets/img/img-pc/beian.png" style="margin-bottom: -3px;margin-right: 6px;">渝公网安备50010502003356号</a>
      </p>   
      <p><a target="_blank" href="https://beian.miit.gov.cn/?recordcode=2022007003">渝ICP备2022007003号</a></p>

  </div>
  </div>
</div>
</template>

<script>
import router from "@/router";
import svgicon from "./svgicon.vue";

export default {
  components: { svgicon, router },
  data() {
    return {
      iconFileName: "logob",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.footer {
  background-color: #cc1a1a;
  min-height: 300px;
  color: #fff;
  display: flex;
  justify-content: center;
}
.router-link-active {
  text-decoration: none;
  color: #fff !important;
}

.footerbox {
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}
.footer-left {
  width: 10%;
  margin-top: 39px;
  padding-left: 40px;
  .footercard {
    width: 130px;
    height: auto;
    img {
      width: 100%;
    }
    p {
      margin: 10px 0;
    }
  }
}
.footer-right {
  margin-top: 50px;
  display: flex;
  text-decoration: none;
  padding-left: 40px;
  ul {
    list-style: none;
    margin: 0px 40px;
    li {
      margin: 20px 0px;
      opacity: 0.8;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1680px) {
  .footer-right {
    margin-top: 50px;
    display: flex;
    text-decoration: none;
    padding-left: 60px;
    ul {
      list-style: none;
      margin: 0px 20px;
      padding-left: 20px;

      li {
        margin: 20px 0px;
        opacity: 0.8;
        font-size: 14px;
      }
    }
  }
}
.footer1 {
  background-color: #b80d0d;
  height: 60px;
  line-height: 60px;
  color: #ffffff;
  text-align: center;
  a {
    margin: 0px 10px;
    color: #ffffff;
  }
}
::v-deep .svg-icon {
  width: 130px;
}

@media screen and (max-width: 1280px) {
  .footer {
    background-color: #cc1a1a;
    width: 100%;
    min-height: auto;
    color: #fff;
    display: flex;
    flex-flow: column;
    padding-top: 10px;
  }
  .footer-left {
    width: 100%;
    margin-top: 20px;
    text-align: center;
    padding-left: 0px;
    img {
      display: block;
      margin: 0 auto;
      margin-bottom: 15px;
    }
  }
  .footer-right {
    width: 100%;
    display: flex;
    flex-flow: column;
    text-align: center;
    padding-left: 0px;
    margin-top: 0px;
    p {
      margin: 10px 0px !important;
      padding: 5px;
      font-size: 16px;
      height: 27px;
      line-height: 12px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.39);
    }
  }
  .footer1 {
    background-color: #b80d0d;
    height: auto;
    line-height: 23px;
    color: #ffffff;
    text-align: center;
    display: flex;
    flex-flow: column;
    padding: 20px;
    font-size: 14px;
    a {
      color: #ffffff;
    }
    p {
      height: 20px;
      margin: 5px;
    }
  }
  .router-link-active {
    color: #fff !important;
  }
  ::v-deep .svg-icon {
    width: 152px !important;
  }
}
</style>
