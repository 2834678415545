<template>
  <div class="header ">
    <div class="headerbox midlehide">
      <div class="header-left">
        <svgicon :iconFileName="iconFileName"></svgicon>
      </div>
      <div class="header-right">
        <el-menu  class="el-menu-demo" mode="horizontal">
          <el-menu-item index="1"><router-link to="/Homepage">首页</router-link></el-menu-item>
          <el-submenu index="2">
            <template slot="title"><router-link to="/solutions" class="twotile">产品与解决方案</router-link></template>
            <el-submenu index="2-1" class="threemenu">
              <template slot="title">党政机关软件</template>
              <router-link to="/solutionsdetail1"><el-menu-item index="2-1-1">政务平台</el-menu-item></router-link>
              <router-link to="/solutionsdetail2"><el-menu-item index="2-1-2">公检法司</el-menu-item></router-link>
              <router-link to="/solutionsdetail3"><el-menu-item index="2-1-3">物联网类</el-menu-item></router-link>
              <router-link to="/solutionsdetail4"><el-menu-item index="2-1-4">大数据类</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-2" class="threemenu">
              <template slot="title">企事业单位管理软件</template>
              <router-link to="/management1"><el-menu-item index="2-2-1">物联网大数据</el-menu-item></router-link>
                <router-link to="/management2"><el-menu-item index="2-2-2">企业管理</el-menu-item></router-link>
                <router-link to="/management3"><el-menu-item index="2-2-3">游戏娱乐</el-menu-item></router-link>
                <router-link to="/management4"><el-menu-item index="2-2-4">行业软件</el-menu-item></router-link>
                <router-link to="/management5"><el-menu-item index="2-2-5">IT运维</el-menu-item></router-link>
                <router-link to="/management6"><el-menu-item index="2-2-6">SAAS平台</el-menu-item></router-link>
            </el-submenu>
            <el-submenu index="2-3" class="threemenu">
              <template slot="title">示例产品</template>
              <router-link to="/product1"><el-menu-item index="2-3-1">公务员数字化管理系统</el-menu-item></router-link>
              <router-link to="/product2"><el-menu-item index="2-3-2">企事业单位数字化管理系统</el-menu-item></router-link>
              <router-link to="/product3"><el-menu-item index="2-3-3">民警绩效考评系统</el-menu-item></router-link>
              <router-link to="/product4"><el-menu-item index="2-3-4">项目实施流程管理系统</el-menu-item></router-link>
              <router-link to="/product5"><el-menu-item index="2-3-5">法院案件审判绩效管理软件</el-menu-item></router-link>
            </el-submenu>
          </el-submenu>
          <el-menu-item index="3"><router-link to="/project-case">项目案例</router-link> </el-menu-item>
            <el-menu-item index="4"><router-link to="/news">新闻资讯</router-link></el-menu-item>
            <el-menu-item index="5"><router-link to="/about-us">关于我们</router-link></el-menu-item>
        </el-menu>
      </div>
  </div>
  <div id="mHeader" class="mobilehide">
    <div class="mHeaderPanel">
      <div class="mHeaderTitlePanel boxspace-between">
        <div class="flex-left">
          <svgicon :iconFileName="iconFileName"></svgicon>
        </div>
        <div class="flex-right" @click="showPopup">
          <van-icon :name="iconname" size="2rem" />
        </div>
      </div>
      <van-popup v-model="show" closeable position="top">
        <div class="flex-left1">
          <svgicon :iconFileName="iconFileName"></svgicon>
        </div>
        <div class="mHeaderContentPanel">
          <el-menu default-active="2" class="el-menu-vertical-demo">
            <router-link to="/Homepage" @click.native="closepopup()"><el-menu-item index="1">
              <span slot="title" style="font-weight: 600;">首页</span>
            </el-menu-item></router-link>
            <el-submenu index="2">
              <template slot="title">
                <router-link to="/solutions" @click.native="closepopup()"><span style="font-weight: 600;">产品与解决方案</span></router-link>
              </template>
              <el-submenu index="2-1" >
                <template slot="title" >党政机关软件</template>
                <router-link to="/solutionsdetail1" @click.native="closepopup()"><el-menu-item index="2-1-1" style="color: #999999;">政务平台</el-menu-item></router-link>
                <router-link to="/solutionsdetail2" @click.native="closepopup()"><el-menu-item index="2-1-2" style="color: #999999;">公检法司</el-menu-item></router-link>
                <router-link to="/solutionsdetail3" @click.native="closepopup()"><el-menu-item index="2-1-3" style="color: #999999;">物联网类</el-menu-item></router-link>
                <router-link to="/solutionsdetail4" @click.native="closepopup()"><el-menu-item index="2-1-4"  style="color: #999999;">大数据类</el-menu-item></router-link>
              </el-submenu>
              <el-submenu index="2-2">
                <template slot="title">企事业单位管理软件</template>
                <router-link to="/management1" @click.native="closepopup()"><el-menu-item index="2-2-1" style="color: #999999;">物联网大数据</el-menu-item></router-link>
                <router-link to="/management2" @click.native="closepopup()"><el-menu-item index="2-2-2" style="color: #999999;">企业管理</el-menu-item></router-link>
                <router-link to="/management3" @click.native="closepopup()"><el-menu-item index="2-2-3" style="color: #999999;">游戏娱乐</el-menu-item></router-link>
                <router-link to="/management4" @click.native="closepopup()"><el-menu-item index="2-2-4" style="color: #999999;">行业软件</el-menu-item></router-link>
                <router-link to="/management5" @click.native="closepopup()"><el-menu-item index="2-2-5" style="color: #999999;">IT运维</el-menu-item></router-link>
                <router-link to="/management6" @click.native="closepopup()"><el-menu-item index="2-2-6" style="color: #999999;">SAAS平台</el-menu-item></router-link>
              </el-submenu>
              <el-submenu index="2-3">
                <template slot="title">示例产品</template>
                <router-link to="/product1" @click.native="closepopup()"><el-menu-item index="2-3-1" style="color: #999999;">公务员数字化管理系统</el-menu-item></router-link>
                <router-link to="/product2" @click.native="closepopup()"><el-menu-item index="2-3-2" style="color: #999999;">企事业单位数字化管理系统</el-menu-item></router-link>
                <router-link to="/product3" @click.native="closepopup()"><el-menu-item index="2-3-3" style="color: #999999;">民警绩效考评系统</el-menu-item></router-link>
                <router-link to="/product4" @click.native="closepopup()"><el-menu-item index="2-3-4" style="color: #999999;">项目实施流程管理系统</el-menu-item></router-link>
                <router-link to="/product5" @click.native="closepopup()"><el-menu-item index="2-3-5" style="color: #999999;">法院案件审判绩效管理软件</el-menu-item></router-link>
              </el-submenu>
            </el-submenu>
            <router-link to="/project-case" @click.native="closepopup()"><el-menu-item index="3">
              <span slot="title" style="font-weight: 600;">项目案例</span>
            </el-menu-item></router-link>
            <router-link to="/news" @click.native="closepopup()"><el-menu-item index="4">
              <span slot="title" style="font-weight: 600;">新闻资讯</span>
            </el-menu-item></router-link>
            <router-link to="/about-us" @click.native="closepopup()"><el-menu-item index="5">
              <span slot="title" style="font-weight: 600;">关于我们</span>
            </el-menu-item></router-link>
          </el-menu>
        </div>
      </van-popup>
    </div>
  </div>
  </div>
</template>
<script>
import svgicon from './svgicon.vue'

export default {
  components: { svgicon },

  data(){
    return{
      iconFileName:'logoh',
      show: false,
      iconname: "wap-nav",
    }
  },
  methods: {
    showPopup() {
      this.show = !this.show;
      // if(this.show == true){
      //   this.iconname = 'cross'
      //   console.log('ssss',this.show)
      // }else if(this.show == false){
      //   console.log('ssss')
      //   this.iconname = 'wap-nav'
      // }
      
    },
    
  },
}
</script>
<style lang="scss" scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  font-size: 22px;
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: nowrap;
}
.header-left{
  width: 10%;
  z-index: 999;
}
.header-left img{
  margin: 20px 0;
  display: block;

}
.headerbox{
  width: 70%;
  margin: 0 auto;
  display: flex;
  flex-wrap: nowrap;
}
.header-right {
  width: 80%;
  padding-left: 60px;
  display: flex;
}
::v-deep  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
  border-bottom: 2px solid #CC1A1A !important;
  color: #CC1A1A !important;
}

::v-deep .el-menu--horizontal>.el-menu-item.is-active{
  border-bottom-color:#fff !important;
  color: #333333 !important;
}
::v-deep .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
  border-bottom-color:#fff !important;
  color: #333333 !important;
}

::v-deep .el-menu.el-menu--horizontal{
  border-bottom:0px;
}
::v-deep .el-menu--horizontal>.el-menu-item{
  color: #333333;
  margin: 10px 50px;
}
::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
  color: #333333;
  margin: 10px 50px;
  .el-icon-arrow-down:before{
    content: '';
  }
}

@media screen and (max-width: 1880px) {
  ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
  color: #333333;
  margin: 10px 1.5em;
  .el-icon-arrow-down:before{
    content: '';
  }
}
::v-deep .el-menu--horizontal>.el-menu-item{
  color: #333333;
  margin: 10px 1.5em;
}

}




::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
  border-bottom: 2px solid #CC1A1A !important;
  color: #CC1A1A !important;
}

.el-submenu ::v-deep .el-submenu__title {
  color: #333333 !important;
}

.el-submenu ::v-deep .el-submenu__title ::v-deep  .el-menu-item{
    color: #333333 !important;
}

// 有二级菜单的标题及子菜单菜单字体颜色
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-sub-menu__title {
	color: #333333 !important;
}

.threemenu{
  // 二级菜单hover状态下字体颜色
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #fff !important;
    background-color: #CC1A1A;
    border-bottom:0px !important;
  }
  .el-submenu ::v-deep .el-submenu__title  :hover {
  color: #fff !important;
  background-color: #CC1A1A;
      
}
  ::v-deep .el-submenu__title i {
    color: #FFFFFF !important;
  }
 
}


.threemenu.el-submenu ::v-deep .el-submenu__title:hover {
  color: #fff !important;
  background-color: #CC1A1A;
}


@media screen and (max-width:1280px) {
  .van-popup--top {
  padding-top:60px ;
  overflow-x: hidden;
}
::v-deep .van-overlay {
  background-color: #fff !important;
  opacity: 0;
  z-index: 998;
}
.flex-left1{
  position: absolute;
  z-index: 2020;
  top: 16px;
  left: 1rem;
}
#mHeader {
  .mHeaderPanel {
    width: 100%;
    height: 60px;
    background: #ffffffff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .mHeaderTitlePanel {
    height: 100%;
    padding: 0 0.43rem 0 0.34rem;
  }
  .logoImg {
    width: 2.97rem;
    height: 0.63rem;
  }
  .downIconImg {
    width: 0.41rem;
    height: 0.32rem;
  }
  ::v-deep .van-popup__close-icon--top-right {
    right:  1.5rem;
    margin-top: 12px;
    color: #000;
    font-size: 2rem;
  }
  .flex-left {
    margin-left: 1rem;
    margin-top: -2px;
  }
  .flex-right {
    margin-right: 1rem;
    margin-top: 22px;
  }
  /*内容*/
  .mHeaderContentPanel {
    padding: 0.87rem 0 0 0.12rem;
    background: #fff;
  }
  ::v-deep .el-menu-item{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #E8E8E8;
  }
  ::v-deep .el-submenu__title{
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #E8E8E8;
  }
  ::v-deep .el-menu-item.is-active {
    color: #CC1A1A;
  }

  ::v-deep .el-submenu__title:hover{
    background-color:#fff ;
    color: #CC1A1A;
  }
  ::v-deep .el-menu-item:focus, .el-menu-item:hover{
    background-color:#fff ;
    color: #CC1A1A;
  }
  ::v-deep .el-submenu.is-active .el-submenu__title:hover{
    background-color: #ffffff;
    color: #CC1A1A;
}

  ::v-deep .el-submenu__title i{
    color: #000;
  }
  ::v-deep .el-submenu__title i:hover{
    color: #CC1A1A !important;
  }
  ::v-deep .el-submenu__title:hover i{
    color: #CC1A1A !important;

  }
  ::v-deep .el-submenu__title:hover a{
    color: #CC1A1A !important;
}

  
}
  

}

</style>
