<!--
 * @Author: zshawk1982
 * @Date: 2021-09-18 10:36:11
 * @LastEditTime: 2021-09-23 13:35:09
 * @LastEditors: zshawk1982
 * @Description: 
 * @FilePath: \media_web_client\src\views\Home.vue
-->
<template>
  <div class="about">
    <el-container>
      <el-header><v-header /></el-header>
      <el-main>
        <div class="content-box">
          <div class="content">
            <router-view >
              
            </router-view>
          </div>
        </div>
      </el-main>
      
    </el-container>



  </div>
</template>
<script>
import vHeader from '../components/Header.vue'
import vfooter from '../components/footer.vue'


export default {
  components: {
    vHeader,
    vfooter,
  },
  // setup() {
  //   const store = useStore()
  //   const tagsList = []
  //   const collapse = computed(() => store.state.collapse)
  //   return {
  //     tagsList,
  //     collapse,
  //   }
  // },
}
</script>
<style lang="scss" scoped>
body {
  margin: 0 !important;
}

::v-deep .el-header{
  padding: 0px;
}
::v-deep .el-main{
  padding: 0px;
}
::v-deep .el-footer{
  padding: 0px;
}
.header{
  position: fixed;
  z-index: 999;
}
</style>
